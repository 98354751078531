<template>
  <div align="center">
  <v-container grid-list-md></v-container>
  <v-card
    class="mx-auto"
    max-width="430"
  >
    <v-img
      src="../assets/CommingSoonKlayPunks.png"
      height="430px"
    ></v-img>

    <v-card-title>
      KlayPunks
    </v-card-title>

    <v-card-subtitle align="left">
      Unique, collectible tokens that live on the Klaytn blockchain.
    </v-card-subtitle>

    <v-card-actions>
       <v-spacer></v-spacer>

      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          COMING SOON
          <br><br>klaypunks@gmail.com
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>

  </div>

<!-- <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="560"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      height="560"
      src="../assets/CommingSoonKlayPunks.png"
    ></v-img>

    <v-card-title>Klay Punks #0</v-card-title>

    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
      </v-row>

    </v-card-text>

    <v-divider class="mx-4"></v-divider>

  </v-card> -->
  <!-- <v-container>
    <v-row class="text-center">
      <v-card center>
      <v-col cols="12">
        <v-img
          :src="require('../assets/CommingSoonKlayPunks.png')"
          class="my-3"
          contain
          height="560"
        />
      </v-col>
      </v-card>
    </v-row>
  </v-container> -->
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({
    show: false
  })
}
</script>
